.table-form-input {
    font-family: var(--base-font);
    padding: 5px;
    margin: 1rem;
    outline: none;
    border: none;
    border-bottom: 1px solid #707070;
    max-width: 300px;
}

.table-form-input::placeholder {
    font-family: sans-serif;
    color: #9E9E9E;
}

.table-form-input:focus {
    border-bottom: 2px solid #555C70;
}

.table-form-button {
    font-family: var(--base-font);
    background-color: #2A61FC;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px 30px;
    max-width: 150px;
    border: none;
    margin-right: 2%;
}

.table-form-button:hover {
    opacity: 0.8;
}

.table-form-error {
    color: rgb(197, 5, 5);
    font-weight: bold;
    font-size: 0.8em;
    padding: 10px;
}


.table-form {
    max-height: 1000px;
    transition: max-height 700ms ease-in, opacity 1s ease-in;
    opacity: 1;
}

.table-form.collapsed {
    overflow: hidden;
    padding: 0 !important;
    max-height: 0px;
    opacity: 0;
    transition: max-height 800ms ease-in, opacity 400ms ease-in, padding 1s ease-in;
}