.login-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F8FAFF;
    /*light blueish white*/
}

.login-card {
    display: flex;
    max-width: 550px;
    max-height: 450px;

    width: 70%;
    height: 50%;

    border-radius: 15px;

    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    background-image: url('../images/Background@2x.png');
    background-size: cover;

}


.login-glassPane {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 40%;
    height: 100%;
    border-radius: 15px 0 0 15px;
    background-color: #638CFF30;
    padding: 15px;
    position: relative;
    z-index: 1;

    transition: transform 700ms ease;
}

.move-right {
    transform: translateX(240%);
    border-radius: 0 15px 15px 0;
}


.login-glassPane h3 {
    color: #ffffff;
    position: absolute;
    top: 25%;
    align-self: center;
}

.login-glassPane p {
    color: #ffffff;
    font-size: 0.8rem;
    position: absolute;
    top: 35%;
    text-align: center;
}

.login-glassPane span {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    position: absolute;
    top: 60%;
    align-self: center;
    border: 1px solid #5e87f8;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

}

.login-glassPane span:hover {
    background-color: #5e87f8;
}

.login-form {
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 0 15px 15px 0;
    padding: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;

    transition: transform 700ms ease;
}

.move-left {
    transform: translateX(-42%);
    border-radius: 15px 0 0 15px;
}

.login-form h3 {
    margin-top: 20%;
    margin-bottom: 5%;
    color: #638CFF;
    align-self: center;
}

.login-form input {
    width: 80%;
    margin: 3%;
    padding: 8px;
    align-self: center;
    border-radius: 5px;
    outline: none;
    background-color: #DCDCDC;
    border: none;
    color: #5e5e5e;
}

.login-form input::placeholder {
    font-size: 0.7rem;
}

.login-form span {
    margin: 3%;
    align-self: center;
    background-color: #2A61FC;
    padding: 10px 30px;
    color: white;
    font-weight: bold;
    font-size: 0.7rem;
    border-radius: 10px;
    cursor: pointer;
}

.login-form span:hover {
    background-color: #2a62fccb;
}


.login-form p {
    align-self: center;
    font-weight: bold;
    color: #ccc;
    font-size: 0.6rem;
    text-decoration: underline;
    cursor: pointer;
}

.login-form p.error {
    margin-top: 1%;
    color: rgb(204, 0, 0);
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .login-card {
        width: 90%;
        height: auto;
    }

    .login-form h3 {
        font-size: 1rem;
    }

    .move-right {
        transform: translateX(234%);
    }

    .login-glassPane h3 {
        font-size: 0.8rem;
        position: relative;
        top: auto;
    }

    .login-glassPane {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: #638cff75;
    }

    .login-glassPane p {
        font-size: 0.7rem;
        text-align: left;
        position: relative;
        top: auto;
    }

    .login-glassPane span {
        font-size: 0.6rem;
        position: relative;
        top: auto;
        align-self: baseline;
        text-align: center;

    }
}