@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

body {
    --primary: #1e88ef;
    --primary-variant: #1565c0;
    --secondary: #ff9800;
    --secondary-variant: #f57c00;
    --background: #eceff1;
    --surface: #ffffff;
    --error: #c62828;
    --on-primary: #ffffff;
    --on-secondary: #000000;
    --on-background: #ffffff;
    --on-surface: #292a2b;
    --on-error: #ffffff;

    --custom-gray: #C7CACC;

    /* --base-font: 'Raleway', sans-serif; */
    --base-font: 'Roboto Condensed', sans-serif;
    --alt-font: 'Lato', sans-serif;
}

html,
body,
#root,
.app {
    height: 100%;
    display: flex;
    flex-direction: column;
}