@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
* {
  margin: 0
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    --primary: #1e88ef;
    --primary-variant: #1565c0;
    --secondary: #ff9800;
    --secondary-variant: #f57c00;
    --background: #eceff1;
    --surface: #ffffff;
    --error: #c62828;
    --on-primary: #ffffff;
    --on-secondary: #000000;
    --on-background: #ffffff;
    --on-surface: #292a2b;
    --on-error: #ffffff;

    --custom-gray: #C7CACC;

    /* --base-font: 'Raleway', sans-serif; */
    --base-font: 'Roboto Condensed', sans-serif;
    --alt-font: 'Lato', sans-serif;
}

html,
body,
#root,
.app {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.login-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F8FAFF;
    /*light blueish white*/
}

.login-card {
    display: flex;
    max-width: 550px;
    max-height: 450px;

    width: 70%;
    height: 50%;

    border-radius: 15px;

    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    background-image: url(/static/media/Background@2x.29454832.png);
    background-size: cover;

}


.login-glassPane {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 40%;
    height: 100%;
    border-radius: 15px 0 0 15px;
    background-color: #638CFF30;
    padding: 15px;
    position: relative;
    z-index: 1;

    transition: -webkit-transform 700ms ease;

    transition: transform 700ms ease;

    transition: transform 700ms ease, -webkit-transform 700ms ease;
}

.move-right {
    -webkit-transform: translateX(240%);
            transform: translateX(240%);
    border-radius: 0 15px 15px 0;
}


.login-glassPane h3 {
    color: #ffffff;
    position: absolute;
    top: 25%;
    align-self: center;
}

.login-glassPane p {
    color: #ffffff;
    font-size: 0.8rem;
    position: absolute;
    top: 35%;
    text-align: center;
}

.login-glassPane span {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    position: absolute;
    top: 60%;
    align-self: center;
    border: 1px solid #5e87f8;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;

}

.login-glassPane span:hover {
    background-color: #5e87f8;
}

.login-form {
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 0 15px 15px 0;
    padding: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;

    transition: -webkit-transform 700ms ease;

    transition: transform 700ms ease;

    transition: transform 700ms ease, -webkit-transform 700ms ease;
}

.move-left {
    -webkit-transform: translateX(-42%);
            transform: translateX(-42%);
    border-radius: 15px 0 0 15px;
}

.login-form h3 {
    margin-top: 20%;
    margin-bottom: 5%;
    color: #638CFF;
    align-self: center;
}

.login-form input {
    width: 80%;
    margin: 3%;
    padding: 8px;
    align-self: center;
    border-radius: 5px;
    outline: none;
    background-color: #DCDCDC;
    border: none;
    color: #5e5e5e;
}

.login-form input::-webkit-input-placeholder {
    font-size: 0.7rem;
}

.login-form input:-ms-input-placeholder {
    font-size: 0.7rem;
}

.login-form input::placeholder {
    font-size: 0.7rem;
}

.login-form span {
    margin: 3%;
    align-self: center;
    background-color: #2A61FC;
    padding: 10px 30px;
    color: white;
    font-weight: bold;
    font-size: 0.7rem;
    border-radius: 10px;
    cursor: pointer;
}

.login-form span:hover {
    background-color: #2a62fccb;
}


.login-form p {
    align-self: center;
    font-weight: bold;
    color: #ccc;
    font-size: 0.6rem;
    text-decoration: underline;
    cursor: pointer;
}

.login-form p.error {
    margin-top: 1%;
    color: rgb(204, 0, 0);
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .login-card {
        width: 90%;
        height: auto;
    }

    .login-form h3 {
        font-size: 1rem;
    }

    .move-right {
        -webkit-transform: translateX(234%);
                transform: translateX(234%);
    }

    .login-glassPane h3 {
        font-size: 0.8rem;
        position: relative;
        top: auto;
    }

    .login-glassPane {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: #638cff75;
    }

    .login-glassPane p {
        font-size: 0.7rem;
        text-align: left;
        position: relative;
        top: auto;
    }

    .login-glassPane span {
        font-size: 0.6rem;
        position: relative;
        top: auto;
        align-self: baseline;
        text-align: center;

    }
}
.spinner-container {
    display: flex;
}

.spinner-container .ball {
    background-color: white;
    border-radius: 50%;
    margin: 3px;
}

.ball1 {
    animation: pulse 500ms ease-in-out forwards infinite alternate-reverse ;
}

.ball2 {
    animation: pulse 500ms ease-in-out forwards infinite alternate-reverse 100ms;
}

.ball3 {
    animation: pulse 500ms ease-in-out forwards infinite alternate-reverse 200ms;
}

@-webkit-keyframes pulse {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pulse {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.signup-form {
    display: flex;
    flex-direction: column;
}

.signup-form h3 {
    margin: 5%;
}
.dashboard-container {
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    background-color: #f1f1f1; /*should put in a var*/ 
}

.dashboard-content {
    width: 100%;
    height: auto;
    padding-left: 95px;
    padding-top: 20px;

    box-sizing: border-box;
/* 
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 30px fit-content(200px) auto; */
}

.dashboard-heading {
    
    font-size: 1em;
    font-weight: bold;
    color: #555C70 ; /*should put in var*/
    letter-spacing: 1px;
    margin: 5px;
}

.grid-section1 {
    /* grid-column: 1 / 8; */
    /* grid-row: 1 / 2; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* background-color: red; */
}

.grid-section2 {
    /* grid-column: 1 / 8; */
    /* grid-row: 2 / 4; */
    /* background-color: turquoise; */
    overflow: hidden;
}

.grid-section3 {
    /* grid-column: 1 / 8;
    grid-row: 3 / 12; */
    /* background-color: thistle; */
    box-sizing: border-box;
    padding: 5px 15px 5px 15px;
}

.grid-section4 {
    /* grid-column: 8 / 13;
    grid-row: 1 / 12; */
    /* background-color: yellow; */
    padding: 5px 15px 5px 15px;
}

.recent-invoices {
    margin: 5px;
    display: flex;
    width: 100%;
    overflow: auto;
    border-bottom: 1px solid rgba(112, 112, 112, 0.37);
}

.dashboard-text {
    text-decoration: underline;
    font-size: 1em;
    color: #989898;
    /* margin: 5px; */
    padding-left: 15px;
}

.user-details {
    /* margin: 10px; */
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 1%;
    cursor: pointer;
    box-sizing: border-box;
    height: 100%;
}

.dashboard-item {
    color: #9E9E9E;
}

.dashboard-item strong {
    margin-right: 3%;
    color: #383E4D;
}

.user-details table {
    width: 100%;
}

.user-details table tbody tr td {
    margin-top: 500px;
    padding: 10px;

   
}

.table-heading {
   
}

.company-details {
    /* margin: 10px; */
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 1%;
    cursor: pointer;
    box-sizing: border-box;
    height: 100%;
    box-sizing: border-box;
}

.dashboard-heading-company {
    
    font-size: 1em;
    font-weight: bold;
    color: #555C70 ; /*should put in var*/
    letter-spacing: 1px;
    margin: 5px;
    text-align: center;
}

.company-details table {
    width: 100%;
}

.company-details table tbody tr td {
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .dashboard-content {
        margin: 0 ;
        padding: 0;

        display: block;
    }

    table tbody tr td p {
        font-size: small;
    }

    
}
.ri-container {
    width: 100px;
    height: 100px;
    min-width: 100px;
    margin: 5px;
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 5px;
    cursor: pointer;

    transition: box-shadow 300ms ease-in-out;
}

.ri-container:hover {
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.ri-container .title{
    color: #3D3D3D;
    font-size: 0.9em;
    font-weight: 500;
    margin: 3px;
}

.ri-container .inv-num, .ri-container .issued, 
.ri-container .due  {
    font-size: 0.7em;
    font-weight: 500;
    margin: 5px;
}

.ri-container .inv-num {
    color: #9e9e9e;
}

.ri-container .balance {
    font-size: 1.3rem;
    margin: 3px;
}

.ri-container .issued, .ri-container .due {
    color: #cfcfcf;
}
.nav-container {
    position: fixed;
    width: 70px;
    height: 100%;
    box-shadow: 8px 0px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 0 30px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(99, 140, 255, 0.69);
    /*need to add to variable*/
}

.nav-container img {
    padding: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.nav-icon-wrapper {
    display: flex;
    flex-direction: column;
}


.nav-icon-wrapper svg {
    width: 20px;
    height: 20px;
    color: white;
    padding: 10px;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;

    transition: background-color 300ms ease-in-out;
}

.nav-icon-wrapper svg:hover {
    background-color: rgb(99, 141, 255);
}

.nav-icon-wrapper .active {
    background-color: rgb(99, 88, 252);
}


@media only screen and (max-width: 600px) {
    .nav-container {
        width: 100%;
        position: relative;
        display: flex;
        border-radius: 0;
        align-items: flex-start;
        box-sizing: border-box;
        height: -webkit-min-content;
        height: min-content;
    }

    .nav-icon-wrapper {
        flex-direction: row;
        max-height: 400px;
        transition: all 1s ease-in-out;

    }

    .nav-container img {
        margin: 0;
    }

    .mobile-hidden {
        max-height: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
        -webkit-transform-origin: top left;
                transform-origin: top left;
    }
}
.newdocket-container {
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    background-color: #f1f1f1; /*should put in a var*/ 
}

.newdocket-content {
    width: 90%;
    max-width: 1200px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    display: grid;
    grid-template-columns: repeat(auto, 1fr);
}

.newdocket-section1 {
    grid-column: 1 / 8;
}

.newdocket-section2 {
    grid-column: 8 / 13;
    /* background-color: seagreen; */
}

.customer-billing {
    margin: 10px;
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 4%;
    cursor: pointer;
    box-sizing: border-box;
}

.customer-billing form  {
    display: flex;
    flex-direction: column;
}

.customer-billing form input {
    font-family: sans-serif;
    padding: 5px;
    margin: 2%;
    outline: none;
    border: none;
    border-bottom: 1px solid #707070;
    max-width: 300px;
}


.customer-billing form input::-webkit-input-placeholder, .newdocket-company-details form input::-webkit-input-placeholder {
    font-family: sans-serif;
    color: #9E9E9E;
}


.customer-billing form input:-ms-input-placeholder, .newdocket-company-details form input:-ms-input-placeholder {
    font-family: sans-serif;
    color: #9E9E9E;
}


.customer-billing form input::placeholder, 
.newdocket-company-details form input::placeholder {
    font-family: sans-serif;
    color: #9E9E9E;
}

.customer-billing form input:focus,
.newdocket-company-details form input:focus {
    border-bottom: 2px solid #555C70;
}

.customer-billing form input.newdocket-button {
    background-color: #2A61FC;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px 30px ; 
    max-width: 150px;
    border: none;
}

.newdocket-button:hover {
    opacity: 0.8;
}

.newdocket-error {
    color: rgb(197, 5, 5);
    font-weight: bold;
    font-size: 0.8em;
    padding: 10px;
}

.newdocket-company-details {
    margin: 10px;
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 4%;
    cursor: pointer;
    box-sizing: border-box;
}

.newdocket-company-details form  {
    display: flex;
    flex-direction: column;
}

.newdocket-company-details form input {
    font-family: sans-serif;
    padding: 5px;
    margin: 5%;
    outline: none;
    border: none;
    border-bottom: 1px solid #707070;
}

@media (max-width: 1153px) {
    .newdocket-content {
        margin-left: 75px;
        margin-right: 0 ;
    }
}

@media only screen and (max-width: 600px) {

    .newdocket-content {
        display: block;
        width: 100%;
       margin: 0;
    }

}
.btn-primary {
    color: var(--on-primary);
    background-color: var(--primary); 
}

.btn-primary:hover {
    background-color: var(--primary-variant);
}

.btn-secondary { 
    color: var(--on-secondary);
    background-color: var(--secondary);
}

.btn-secondary:hover { 
    background-color: var(--secondary-variant);
}

.btn-custom-button {
    display: flex;
    flex-direction: column;
}

.btn-custom-button > div {
    display: flex;
    justify-content: center ;
    font-family: var(--base-font);
    font-weight: bold;
    font-size: medium;
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 0.2em;
    letter-spacing: 0.1em;
    cursor: pointer;
}

.custominput-inputs {
    display: flex;
    flex-direction: column;
    min-height: auto;
}

.custominput-input-item {
    padding: 0.8rem;
    margin: 0.5rem;
    border: 2px solid var(--custom-gray);
    border-radius: 0.5rem;
    font-family: var(--base-font);
    font-size: 1rem;
    letter-spacing: 0.1rem;
    outline: none;
}

.custominput-input-item:focus{
    border: 2px solid var(--secondary);
    border-radius: 0.5em;
}

.custominput-input-item::-webkit-input-placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.custominput-input-item:-ms-input-placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.custominput-input-item::placeholder {
    font-family: var(--base-font);
    font-size: 1.2em;
    letter-spacing: 0.1em;
}

.custominput-label {
    font-family: var(--alt-font);
    font-weight: bold;
    font-size: 0.9em;
    margin-left: 1.5em;
}

@media screen and (max-height: 600px) {
    .custominput-input-item {
        padding: 0.5rem;
        margin: 0.5rem;
        border: 2px solid var(--custom-gray);
        border-radius: 0.5rem;
        font-family: var(--base-font);
        font-size: 1rem;
        letter-spacing: 0.1rem;
        outline: none;
    }
}
.docket-container {
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    background-color: #f1f1f1; /*should put in a var*/ 
    flex-direction: column;
}

.docket-item-container{
    margin-left: 95px;
    margin-top: 20px;
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.docket-item-container h1 {
    grid-column: span 2;
    
    font-size: 1em;
    font-weight: bold;
    color: #555C70 ; /*should put in var*/
    letter-spacing: 1px;
    margin: 5px;
}

@media only screen and (max-width: 600px) {

    
    .docket-item-container{
        display: flex;
        flex-direction: column;
        margin: 0px;
        padding: 5px;
    }

  }
.docket-item {
    padding: 10px;
    border-radius: 5px;
    background-color: var(--surface);
    transition: box-shadow 500ms;
    cursor: pointer;
    position: relative;
    min-width: 300px;
}


.docket-item-icons {
    position: absolute;
    right: 3%;
    display: flex;
    padding: 5px;
    color: #555C70;
    font-size: 0.9rem;
}


.docket-item-icon-style {
    margin: 3px;
    text-decoration: underline;
}

.docket-item-icon-style:hover {
    font-weight: bold;
}

.docket-item-header {
    font-size: 1rem;
    font-weight: bold;
    color: #555C70 ; /*should put in var*/
    letter-spacing: 1px;
}

.docket-item-table-container {
    padding-top: 5px;
}

.docket-item-title {
    font-size:0.9rem;
    font-weight: bold;
    color: black ; /*should put in var*/
}

.docket-item-subtitle {
    font-size:0.7rem;
    font-weight: bold;
    margin-bottom: 3px;
    color: #9E9E9E ; /*should put in var*/
}

.docket-item-balance {
    font-size: 1.8rem;
    font-weight: 400;
    color: black ; /*should put in var*/
    letter-spacing: 2px;
}

.docket-item:hover {
    box-shadow: -1px 0px 10px 3px rgba(168,168,168,1);
}

.docket-date {
    position: absolute;
    right: 3%;
    bottom: 5%;
    display: flex;
    font-size: x-small;
    color: #555C70;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    

}




.detail-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: var(--background);
    font-family: var(--alt-font);
}

.detail-container>h1 {
    align-self: center;
    color: var(--primary);
    margin-top: 5px;
}

.detail-date {
    display: flex;
    padding: 20px 10%;
    justify-content: flex-end;
    align-items: center;
}

.detail-date>h3 {
    margin-right: 10px;
}

.detail-header {
    display: flex;
    padding: 20px 10%;
    font-family: var(--base-font);
}

.detail-header>table {
    width: 100%;
    border-collapse: collapse;
    margin: 4px;
}

.detail-header>table>tr td {
    padding: 5px;
}

td.table-top {
    display: flex;
}

.detail-options {
    padding: 10px 10%;
    font-weight: bold;
}

.detail-options label input {
    margin-right: 5px;
}

.detail-item-container {
    padding: 10px 10%;
}

.detail-item-table>thead>tr>th {
    background-color: var(--secondary);
    padding: 5px;
}

.detail-item-table>tbody>tr:nth-child(even) {
    background-color: #d8e1ed;
}

.detail-item-table>tbody>tr:nth-child(odd) {
    background-color: #ede4d8;
}

.detail-item-table>tbody>tr>td {
    padding: 5px;
}

.detail-item-table {
    width: 100%;
}


.detail-footer {
    display: flex;
    margin: 20px 10%;
    justify-content: flex-end;
}

.selected {
    background-color: rgba(0, 255, 0, 0.39) !important;
}

.detail-exports {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: flex-end;
}

.subtotal-container {
    display: flex;
    justify-content: flex-end;
    margin: 20px 10%;
    border-top: 2px solid #CCC;
    border-bottom: 2px solid #CCC;
}

.subtotal-table {
    width: 50%;
}

td.align-totals {
    text-align: right;
}



@media only screen and (max-width: 600px) {
    .detail-footer .btn-primary {
        font-size: x-small;
    }

    .detail-header {
        display: flex;
        padding: 5px 1%;
        font-family: var(--base-font);
        font-size: 0.75em;
    }

    .detail-item-container {
        padding: 10px 1%;
    }

    .subtotal-container {
        margin: 10px 1%;
    }

    .subtotal-table {
        width: 100%;
        font-size: 0.9em;
    }
}
.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    transition: opacity 800ms;
}

.hidden {
   opacity: 0;
   min-height: 0;
   height: 0;
   overflow: hidden;
}

.overlay-card {
    width: 40%;
    /* box-shadow: 0em 0em 0.5em 0.3em #888888; */
    min-width: 20em;
    background-color: var(--surface);
    padding: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 1em;
    font-family: var(--alt-font);
    color: var(--on-surface);
}

.overlay-error {
    display: flex;
    justify-content: center;
    padding: 0.5em;
    font-family: var(--base-font);
    font-weight: bold;
    color: var(--error);
}
.submition-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: var(--base-font);
    justify-content: center;
    align-items: center;
    font-weight: bold;
    size: 1.3em;
}

.end-link {
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}
.info-container {
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    background-color: #f1f1f1; /*should put in a var*/ 
}

.info-card {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    margin-bottom: 4%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 4%;
    cursor: pointer;
    box-sizing: border-box;
    justify-content: space-evenly;
}

.info-card h3 {
    font-weight: bold;
    color: #555C70 ; /*should put in var*/
    letter-spacing: 1px;
    margin: 5px;
    text-align: center;
}

.info-item input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    border: none;
    outline: none;
    border-bottom: 1px solid #555C70;
    font-size: 16px;
}

.info-item input:focus {
    border-bottom: 2px solid #1b244b;
}

.info-button {
    background-color: #2A61FC;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px 30px ; 
    max-width: 150px;
    border: none;
}

.info-button:hover {
    opacity: 0.8;
}

.error {
    color: rgb(197, 5, 5);
    font-weight: bold;
    font-size: 0.8em;
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .info-container {
       min-height: 100%; 
       box-sizing: border-box;
       padding: 10px;
    }


    .info-card {
        width: 100%;
        height: auto;
    }

    .info-item input {
        box-sizing: border-box;
    }
}
.clients-container {
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    background-color: #f1f1f1;
}

.clients-wrapper {
    width: 100%;
    padding-top: 2%;
    padding-left: 90px;
    box-sizing: border-box;
}

.clients-wrapper h1 {
    font-size: 1em;
    font-weight: bold;
    color: #555C70;
    /*should put in var*/
    letter-spacing: 1px;
    margin: 5px;
}

.client-search {
    padding: 1%;
}

.client-search input {
    width: 100%;
    max-width: 350px;
    padding: 10px;
    font-family: var(--alt-font);
    border: 1px solid #cccccc;
    outline: none;
    border-radius: 6px;
}

.client-search input:focus {
    border-color: #687dc8;
}

.client-actions {
    display: flex;
    flex-direction: row;
    width: 90%;
    background-color: white;
    margin-left: 5px;
    padding: 1rem;
    font-family: var(--base-font);
    box-sizing: border-box;
}

.client-actions .checkbox {
    display: flex;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #cccccc;
}

.client-actions .title {
    margin-left: 20px;
    display: flex;
    flex: 1 1;
    padding: 5px;
}

.client-actions .action {
    font-size: 0.8rem;
    padding: 5px;
    margin: 0 5px;
    background-color: #cccccc;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.client-actions .action:hover,
.client-actions .action:active {
    background-color: #ccccccbd;
}

@media only screen and (max-width: 600px) {
    .clients-wrapper {
        width: 100%;
        padding-top: 5%;
        padding-left: 0px;
    }

    .client-search input {
        max-width: 90%;
        box-sizing: border-box;
    }
}
.table-form-input {
    font-family: var(--base-font);
    padding: 5px;
    margin: 1rem;
    outline: none;
    border: none;
    border-bottom: 1px solid #707070;
    max-width: 300px;
}

.table-form-input::-webkit-input-placeholder {
    font-family: sans-serif;
    color: #9E9E9E;
}

.table-form-input:-ms-input-placeholder {
    font-family: sans-serif;
    color: #9E9E9E;
}

.table-form-input::placeholder {
    font-family: sans-serif;
    color: #9E9E9E;
}

.table-form-input:focus {
    border-bottom: 2px solid #555C70;
}

.table-form-button {
    font-family: var(--base-font);
    background-color: #2A61FC;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px 30px;
    max-width: 150px;
    border: none;
    margin-right: 2%;
}

.table-form-button:hover {
    opacity: 0.8;
}

.table-form-error {
    color: rgb(197, 5, 5);
    font-weight: bold;
    font-size: 0.8em;
    padding: 10px;
}


.table-form {
    max-height: 1000px;
    transition: max-height 700ms ease-in, opacity 1s ease-in;
    opacity: 1;
}

.table-form.collapsed {
    overflow: hidden;
    padding: 0 !important;
    max-height: 0px;
    opacity: 0;
    transition: max-height 800ms ease-in, opacity 400ms ease-in, padding 1s ease-in;
}
