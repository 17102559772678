.docket-container {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    background-color: #f1f1f1; /*should put in a var*/ 
    flex-direction: column;
}

.docket-item-container{
    margin-left: 95px;
    margin-top: 20px;
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 10px;
    column-gap: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.docket-item-container h1 {
    grid-column: span 2;
    
    font-size: 1em;
    font-weight: bold;
    color: #555C70 ; /*should put in var*/
    letter-spacing: 1px;
    margin: 5px;
}

@media only screen and (max-width: 600px) {

    
    .docket-item-container{
        display: flex;
        flex-direction: column;
        margin: 0px;
        padding: 5px;
    }

  }