.newdocket-container {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    background-color: #f1f1f1; /*should put in a var*/ 
}

.newdocket-content {
    width: 90%;
    max-width: 1200px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    display: grid;
    grid-template-columns: repeat(auto, 1fr);
}

.newdocket-section1 {
    grid-column: 1 / 8;
}

.newdocket-section2 {
    grid-column: 8 / 13;
    /* background-color: seagreen; */
}

.customer-billing {
    margin: 10px;
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 4%;
    cursor: pointer;
    box-sizing: border-box;
}

.customer-billing form  {
    display: flex;
    flex-direction: column;
}

.customer-billing form input {
    font-family: sans-serif;
    padding: 5px;
    margin: 2%;
    outline: none;
    border: none;
    border-bottom: 1px solid #707070;
    max-width: 300px;
}


.customer-billing form input::placeholder, 
.newdocket-company-details form input::placeholder {
    font-family: sans-serif;
    color: #9E9E9E;
}

.customer-billing form input:focus,
.newdocket-company-details form input:focus {
    border-bottom: 2px solid #555C70;
}

.customer-billing form input.newdocket-button {
    background-color: #2A61FC;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px 30px ; 
    max-width: 150px;
    border: none;
}

.newdocket-button:hover {
    opacity: 0.8;
}

.newdocket-error {
    color: rgb(197, 5, 5);
    font-weight: bold;
    font-size: 0.8em;
    padding: 10px;
}

.newdocket-company-details {
    margin: 10px;
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 4%;
    cursor: pointer;
    box-sizing: border-box;
}

.newdocket-company-details form  {
    display: flex;
    flex-direction: column;
}

.newdocket-company-details form input {
    font-family: sans-serif;
    padding: 5px;
    margin: 5%;
    outline: none;
    border: none;
    border-bottom: 1px solid #707070;
}

@media (max-width: 1153px) {
    .newdocket-content {
        margin-left: 75px;
        margin-right: 0 ;
    }
}

@media only screen and (max-width: 600px) {

    .newdocket-content {
        display: block;
        width: 100%;
       margin: 0;
    }

}