.nav-container {
    position: fixed;
    width: 70px;
    height: 100%;
    box-shadow: 8px 0px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 0 30px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(99, 140, 255, 0.69);
    /*need to add to variable*/
}

.nav-container img {
    padding: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.nav-icon-wrapper {
    display: flex;
    flex-direction: column;
}


.nav-icon-wrapper svg {
    width: 20px;
    height: 20px;
    color: white;
    padding: 10px;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;

    transition: background-color 300ms ease-in-out;
}

.nav-icon-wrapper svg:hover {
    background-color: rgb(99, 141, 255);
}

.nav-icon-wrapper .active {
    background-color: rgb(99, 88, 252);
}


@media only screen and (max-width: 600px) {
    .nav-container {
        width: 100%;
        position: relative;
        display: flex;
        border-radius: 0;
        align-items: flex-start;
        box-sizing: border-box;
        height: min-content;
    }

    .nav-icon-wrapper {
        flex-direction: row;
        max-height: 400px;
        transition: all 1s ease-in-out;

    }

    .nav-container img {
        margin: 0;
    }

    .mobile-hidden {
        max-height: 0;
        transform: scale(0);
        transform-origin: top left;
    }
}