.clients-container {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    background-color: #f1f1f1;
}

.clients-wrapper {
    width: 100%;
    padding-top: 2%;
    padding-left: 90px;
    box-sizing: border-box;
}

.clients-wrapper h1 {
    font-size: 1em;
    font-weight: bold;
    color: #555C70;
    /*should put in var*/
    letter-spacing: 1px;
    margin: 5px;
}

.client-search {
    padding: 1%;
}

.client-search input {
    width: 100%;
    max-width: 350px;
    padding: 10px;
    font-family: var(--alt-font);
    border: 1px solid #cccccc;
    outline: none;
    border-radius: 6px;
}

.client-search input:focus {
    border-color: #687dc8;
}

.client-actions {
    display: flex;
    flex-direction: row;
    width: 90%;
    background-color: white;
    margin-left: 5px;
    padding: 1rem;
    font-family: var(--base-font);
    box-sizing: border-box;
}

.client-actions .checkbox {
    display: flex;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #cccccc;
}

.client-actions .title {
    margin-left: 20px;
    display: flex;
    flex: 1;
    padding: 5px;
}

.client-actions .action {
    font-size: 0.8rem;
    padding: 5px;
    margin: 0 5px;
    background-color: #cccccc;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.client-actions .action:hover,
.client-actions .action:active {
    background-color: #ccccccbd;
}

@media only screen and (max-width: 600px) {
    .clients-wrapper {
        width: 100%;
        padding-top: 5%;
        padding-left: 0px;
    }

    .client-search input {
        max-width: 90%;
        box-sizing: border-box;
    }
}