.detail-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: var(--background);
    font-family: var(--alt-font);
}

.detail-container>h1 {
    align-self: center;
    color: var(--primary);
    margin-top: 5px;
}

.detail-date {
    display: flex;
    padding: 20px 10%;
    justify-content: flex-end;
    align-items: center;
}

.detail-date>h3 {
    margin-right: 10px;
}

.detail-header {
    display: flex;
    padding: 20px 10%;
    font-family: var(--base-font);
}

.detail-header>table {
    width: 100%;
    border-collapse: collapse;
    margin: 4px;
}

.detail-header>table>tr td {
    padding: 5px;
}

td.table-top {
    display: flex;
}

.detail-options {
    padding: 10px 10%;
    font-weight: bold;
}

.detail-options label input {
    margin-right: 5px;
}

.detail-item-container {
    padding: 10px 10%;
}

.detail-item-table>thead>tr>th {
    background-color: var(--secondary);
    padding: 5px;
}

.detail-item-table>tbody>tr:nth-child(even) {
    background-color: #d8e1ed;
}

.detail-item-table>tbody>tr:nth-child(odd) {
    background-color: #ede4d8;
}

.detail-item-table>tbody>tr>td {
    padding: 5px;
}

.detail-item-table {
    width: 100%;
}


.detail-footer {
    display: flex;
    margin: 20px 10%;
    justify-content: flex-end;
}

.selected {
    background-color: rgba(0, 255, 0, 0.39) !important;
}

.detail-exports {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: flex-end;
}

.subtotal-container {
    display: flex;
    justify-content: flex-end;
    margin: 20px 10%;
    border-top: 2px solid #CCC;
    border-bottom: 2px solid #CCC;
}

.subtotal-table {
    width: 50%;
}

td.align-totals {
    text-align: right;
}



@media only screen and (max-width: 600px) {
    .detail-footer .btn-primary {
        font-size: x-small;
    }

    .detail-header {
        display: flex;
        padding: 5px 1%;
        font-family: var(--base-font);
        font-size: 0.75em;
    }

    .detail-item-container {
        padding: 10px 1%;
    }

    .subtotal-container {
        margin: 10px 1%;
    }

    .subtotal-table {
        width: 100%;
        font-size: 0.9em;
    }
}