.submition-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: var(--base-font);
    justify-content: center;
    align-items: center;
    font-weight: bold;
    size: 1.3em;
}

.end-link {
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}