.docket-item {
    padding: 10px;
    border-radius: 5px;
    background-color: var(--surface);
    transition: box-shadow 500ms;
    cursor: pointer;
    position: relative;
    min-width: 300px;
}


.docket-item-icons {
    position: absolute;
    right: 3%;
    display: flex;
    padding: 5px;
    color: #555C70;
    font-size: 0.9rem;
}


.docket-item-icon-style {
    margin: 3px;
    text-decoration: underline;
}

.docket-item-icon-style:hover {
    font-weight: bold;
}

.docket-item-header {
    font-size: 1rem;
    font-weight: bold;
    color: #555C70 ; /*should put in var*/
    letter-spacing: 1px;
}

.docket-item-table-container {
    padding-top: 5px;
}

.docket-item-title {
    font-size:0.9rem;
    font-weight: bold;
    color: black ; /*should put in var*/
}

.docket-item-subtitle {
    font-size:0.7rem;
    font-weight: bold;
    margin-bottom: 3px;
    color: #9E9E9E ; /*should put in var*/
}

.docket-item-balance {
    font-size: 1.8rem;
    font-weight: 400;
    color: black ; /*should put in var*/
    letter-spacing: 2px;
}

.docket-item:hover {
    box-shadow: -1px 0px 10px 3px rgba(168,168,168,1);
}

.docket-date {
    position: absolute;
    right: 3%;
    bottom: 5%;
    display: flex;
    font-size: x-small;
    color: #555C70;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    

}



