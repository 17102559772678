.info-container {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    background-color: #f1f1f1; /*should put in a var*/ 
}

.info-card {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    margin-bottom: 4%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 4%;
    cursor: pointer;
    box-sizing: border-box;
    justify-content: space-evenly;
}

.info-card h3 {
    font-weight: bold;
    color: #555C70 ; /*should put in var*/
    letter-spacing: 1px;
    margin: 5px;
    text-align: center;
}

.info-item input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    border: none;
    outline: none;
    border-bottom: 1px solid #555C70;
    font-size: 16px;
}

.info-item input:focus {
    border-bottom: 2px solid #1b244b;
}

.info-button {
    background-color: #2A61FC;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px 30px ; 
    max-width: 150px;
    border: none;
}

.info-button:hover {
    opacity: 0.8;
}

.error {
    color: rgb(197, 5, 5);
    font-weight: bold;
    font-size: 0.8em;
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .info-container {
       min-height: 100%; 
       box-sizing: border-box;
       padding: 10px;
    }


    .info-card {
        width: 100%;
        height: auto;
    }

    .info-item input {
        box-sizing: border-box;
    }
}