.ri-container {
    width: 100px;
    height: 100px;
    min-width: 100px;
    margin: 5px;
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 5px;
    cursor: pointer;

    transition: box-shadow 300ms ease-in-out;
}

.ri-container:hover {
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.ri-container .title{
    color: #3D3D3D;
    font-size: 0.9em;
    font-weight: 500;
    margin: 3px;
}

.ri-container .inv-num, .ri-container .issued, 
.ri-container .due  {
    font-size: 0.7em;
    font-weight: 500;
    margin: 5px;
}

.ri-container .inv-num {
    color: #9e9e9e;
}

.ri-container .balance {
    font-size: 1.3rem;
    margin: 3px;
}

.ri-container .issued, .ri-container .due {
    color: #cfcfcf;
}