.spinner-container {
    display: flex;
}

.spinner-container .ball {
    background-color: white;
    border-radius: 50%;
    margin: 3px;
}

.ball1 {
    animation: pulse 500ms ease-in-out forwards infinite alternate-reverse ;
}

.ball2 {
    animation: pulse 500ms ease-in-out forwards infinite alternate-reverse 100ms;
}

.ball3 {
    animation: pulse 500ms ease-in-out forwards infinite alternate-reverse 200ms;
}

@keyframes pulse {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}