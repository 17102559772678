.dashboard-container {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    background-color: #f1f1f1; /*should put in a var*/ 
}

.dashboard-content {
    width: 100%;
    height: auto;
    padding-left: 95px;
    padding-top: 20px;

    box-sizing: border-box;
/* 
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 30px fit-content(200px) auto; */
}

.dashboard-heading {
    
    font-size: 1em;
    font-weight: bold;
    color: #555C70 ; /*should put in var*/
    letter-spacing: 1px;
    margin: 5px;
}

.grid-section1 {
    /* grid-column: 1 / 8; */
    /* grid-row: 1 / 2; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* background-color: red; */
}

.grid-section2 {
    /* grid-column: 1 / 8; */
    /* grid-row: 2 / 4; */
    /* background-color: turquoise; */
    overflow: hidden;
}

.grid-section3 {
    /* grid-column: 1 / 8;
    grid-row: 3 / 12; */
    /* background-color: thistle; */
    box-sizing: border-box;
    padding: 5px 15px 5px 15px;
}

.grid-section4 {
    /* grid-column: 8 / 13;
    grid-row: 1 / 12; */
    /* background-color: yellow; */
    padding: 5px 15px 5px 15px;
}

.recent-invoices {
    margin: 5px;
    display: flex;
    width: 100%;
    overflow: auto;
    border-bottom: 1px solid rgba(112, 112, 112, 0.37);
}

.dashboard-text {
    text-decoration: underline;
    font-size: 1em;
    color: #989898;
    /* margin: 5px; */
    padding-left: 15px;
}

.user-details {
    /* margin: 10px; */
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 1%;
    cursor: pointer;
    box-sizing: border-box;
    height: 100%;
}

.dashboard-item {
    color: #9E9E9E;
}

.dashboard-item strong {
    margin-right: 3%;
    color: #383E4D;
}

.user-details table {
    width: 100%;
}

.user-details table tbody tr td {
    margin-top: 500px;
    padding: 10px;

   
}

.table-heading {
   
}

.company-details {
    /* margin: 10px; */
    border-radius: 15px;
    background-color: #ffffff ;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 1%;
    cursor: pointer;
    box-sizing: border-box;
    height: 100%;
    box-sizing: border-box;
}

.dashboard-heading-company {
    
    font-size: 1em;
    font-weight: bold;
    color: #555C70 ; /*should put in var*/
    letter-spacing: 1px;
    margin: 5px;
    text-align: center;
}

.company-details table {
    width: 100%;
}

.company-details table tbody tr td {
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .dashboard-content {
        margin: 0 ;
        padding: 0;

        display: block;
    }

    table tbody tr td p {
        font-size: small;
    }

    
}